window.Validator = (function() {
  var _constraints = null;
  var _handleSuccess = null;

  /**
   * Form handle.
   * 
   * @param {*} form 
   * @param {*} input 
   */
  function handleFormSubmit(ev) {
    ev.preventDefault();

    // validate the form against the constraints
    var errors = validate(this, _constraints);

    // then we update the form to reflect the results
    showErrors(this, errors || {});

    if (!errors) {
      if (_handleSuccess) {
        _handleSuccess(this);
      } else {
        this.submit();
      }
    }
  }

  // Updates the inputs with the validation errors
  function showErrors(form, errors) {
    var inputs = form.querySelectorAll("input[name]:not([type='hidden']):not([type='submit']), textarea[name], select[name]");

    // We loop through all the inputs and show the errors for that input
    for (var input of inputs) {
      // Since the errors can be null if no errors were found we need to handle
      // that
      showErrorsForInput(input, errors && errors[input.name]);
    }
  }

  // Shows the errors for a specific input
  function showErrorsForInput(input, errors) {
    // This is the root of the input.
    // Find where the error messages will be insert into.
    var formGroup = closestParent(input, "form-group");
    var errorEl = document.createElement('span');
    errorEl.classList.add("input-error");

    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup);

    // If we have errors, we first mark the group has having errors
    if (errors) {
      formGroup.classList.add("has-error");
      errorEl.innerText = errors[0];
      formGroup.appendChild(errorEl);
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent(child, className) {
    if (!child || child == document) {
      return null;
    }

    if (child.classList.contains(className)) {
      return child;
    } else {
      return closestParent(child.parentNode, className);
    }
  }

  function resetFormGroup(formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove("has-error");

    var errorEl = formGroup.getElementsByClassName("input-error");

    if (errorEl.length > 0) {
      errorEl[0].remove();
    }
  }

  function allowSubmit(form) {
    var submits = form.querySelectorAll("input[type='submit'], button[type='submit']");

    for (var submit of submits) {
      submit.disabled = false;
    }
  }

  function dontAllowSubmit(form) {
    var submits = form.querySelectorAll("input[type='submit'], button[type='submit']");

    for (var submit of submits) {
      submit.disabled = true;
    }
  }

  /**
   * Check the initial form state and disable the submit button if form has errors.
   */
  function checkInitialFormState(form) {
    var errors = validate(form, _constraints);

    if (!errors) {
      allowSubmit(form);
    } else {
      dontAllowSubmit(form);
    }
  }

  function checkFormState(form, input, constraints) {
    var errors = validate(form, constraints) || {};
    showErrorsForInput(input, errors[input.name]);

    if (Object.keys(errors).length === 0) {
      allowSubmit(form);
    } else {
      dontAllowSubmit(form);
    }
  }

  function handleValidation(event) {
    var input = event.target;

    // Ignore validations on press tab key.
    if (event.type === 'keyup' && event.keyCode === 9) {
      return ;
    }

    checkFormState(this, input, _constraints);
  }

  function check(form, constraints, successCb) {
    _constraints = constraints;
    _handleSuccess = successCb;

    // Check initial form state.
    checkInitialFormState(form);

    // Form event submit.
    form.addEventListener("submit", handleFormSubmit);
    form.addEventListener('keyup', handleValidation, false);
    form.addEventListener('change', handleValidation, false);
    form.addEventListener('input', handleValidation, false);
  }

  return {
    check: check
  }
})();
